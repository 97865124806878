import React from "react";
import SundogHeroSection from "./SundogHeroSection";
import SundogInstantCardRocks from "./SundogInstantCardRocks";
import SundogMap from "./SundogMap";

const Sundog = () => {
  return (
    <>
      <SundogHeroSection />
      <SundogInstantCardRocks />
      <SundogMap />
    </>
  );
};

export default Sundog;
