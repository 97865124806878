import React from "react";
import {
  BalackCardFAQBackground,
  BlackCardGroupImages,
} from "../../../assets/images";

const HeroSection = () => {
  return (
    <>
      <div className="font-ppmori relative">
        <div className=" md:h-[600px] overflow-hidden  rounded-[30px] md:bg-[#CDDFF0] md:flex hero-wave-bg md:bg-right md:bg-bottom bg-no-repeat">
          <img
            src={BalackCardFAQBackground}
            className="w-full h-full object-right absolute top-0 right-0 hidden md:block"
            alt=""
          />
          <div className="hero-contents">
            {/* left side  */}
            <div className="flex-1 relative z-[1] pb-10 md:pb-0 px-10 lg:px-20 text-white flex flex-col justify-center">
              <h2 className="text-4xl font-normal md:text-5xl lg:text-[60px] lg:leading-[65px]">
                Your Crypto, <br /> Your Way
              </h2>
              <h3 className="text-[24px] font-normal lg:text-[28px] max-w-md md:mt-5 mt-2">
                Powered by Mastercard, designed to be part of your everyday
                life. Reloadable and limitless
              </h3>
            </div>
            {/* right side  */}
            <div className="flex-1 flex items-center lg:mr-4 relative z-[1]">
              <img
                src={BlackCardGroupImages}
                alt=""
                className="w-[100%] my-auto z-[100]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
