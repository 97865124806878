import React from "react";
import { Link } from "react-router-dom";
import FaqCard from "../instant-card/integrate/FaqCard";

const faqs = [
  {
    qst: "How do I top up the card?",
    ans: "Zebec Instant Card is a crypto-fiat prepaid card. Soon we'll be offering a feature to introduce card reloading, ATM withdrawal and other debit card features.",
  },
  {
    qst: "Which network supports Zebec Instant and in which country?",
    ans: (
      <p>
        The Zebec Instant is available in 138 countries around the world. In
        some countries it is supported by Visa and in some by both Visa and
        Mastercard networks. Please refer to the
        <strong
          className="text-primary font-semibold hover:underline"
          onClick={() => {
            if (window !== undefined) {
              window.scrollTo(0, 0);
            }
          }}
        >
          <Link to="/visa-master"> Countries and Networks</Link>
        </strong>{" "}
        page for details.
      </p>
    ),
  },
  {
    qst: "Can I use the same card in different countries?",
    ans: (
      <p>
        Zebec Instant Cards can be used anywhere where Visa and Mastercard are
        accepted. See{" "}
        <strong
          className="text-primary font-semibold hover:underline"
          onClick={() => {
            if (window !== undefined) {
              window.scrollTo(0, 0);
            }
          }}
        >
          <Link to="/visa-master"> Countries and Networks</Link>
        </strong>{" "}
        page for details.
      </p>
    ),
  },
  {
    qst: "How do I order the physical card?",
    ans: "Once you successfully purchase the card, follow the link you receive with the details of the virtual and to order a physical card.",
  },
  {
    qst: "Can I get more than one card?",
    ans: "Yes, multiple cards can be created under the same card profile.",
  },
  {
    qst: "Do you have 24/7 customer service for Zebec Instant Cards? ",
    ans: (
      <p>
        Yes, our customer service can be reached 24x7 via{" "}
        <a
          href="mailto:cardsupport@zebec.io"
          className="text-primary font-semibold"
        >
          cardsupport@zebec.io
        </a>
      </p>
    ),
  },
  {
    qst: "Do you plan on supporting additional currencies?",
    ans: "The Initial Zebec Instant Card is available in USD, JPY & INR. We plan to support most other major global currencies soon.",
  },
  {
    qst: "How Do I Track My Spending and Balances?",
    ans: "The dashboard in your card profile allows you to track your spending, view balances, and manage your card settings conveniently.",
  },
  {
    qst: "How Secure Is the Zebec Instant Card?",
    ans: "Security is our top priority. The Zebec Instant Card employs advanced security protocols to protect your transactions and personal information. Plus, being non-custodial, it ensures you always maintain control over your funds.",
  },
  {
    qst: "Can I Withdraw Cash Using the Zebec Instant Card?",
    ans: "Initial version of the Zebec Instant Card is compatible with both POS & E-commerce transactions. Having said that, we plan to introduce this feature soon for our customers. ",
  },
  {
    qst: "What if a merchant charged me in error can I get a refund?",
    ans: "Yes, you need to contact the card Issuer bank via their customer support (contact at the back of the card) to process the refund. ",
  },
  {
    qst: "Do you offer any rewards or incentives?",
    ans: "Rewards and other benefits will be available very soon.",
  },
  {
    qst: "Do I need to have a Zebec App to order a card?",
    ans: "No. Though we’d love to welcome you into our family,  you do not need to have Zebec App web3 App.  Just create a card profile and order your Zebec Instant Card.",
  },
  {
    qst: "If I already have a Zebec App and a connected wallet, do I need to create a new account / dashboard for the Zebec Instant Card?",
    ans: "If you have a Zebec Card account you do not need a new card dashboard or registration. You order a card via your Zebec App log in and connected virtual wallet.",
  },
  {
    qst: "Do I need to reconnect my wallet for each new card? Each transaction or top up?  ",
    ans: "If you already have Zebec App and a connected wallet, you do not need to connect wallet unless you  need to move funds.",
  },
];

const ZebecInstantCardFaqs = () => {
  return (
    <div className="web-padding my-24 gap-6 max-w-4xl mx-auto">
      <div className="max-w-[335px] lg:max-w-[420px]">
        <h1 className="text-[25px] lg:text-[40px] leading-[130%]">
          Zebec Instant Card Q&A
        </h1>
      </div>
      {/* faqs  */}
      <div className="mt-10 md:mt-0">
        {faqs.map((data, index) => {
          return (
            <FaqCard
              maxWidth={"max-w-4xl"}
              {...data}
              key={index}
              index={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ZebecInstantCardFaqs;
