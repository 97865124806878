import React from "react";
import {
  CartIcon,
  RocketIcon,
  WorldIcon,
  ZeroFeeIcon,
} from "../../../assets/icons";

const cards = [
  {
    title: "Zero Fees, Maximum Bravado",
    desc: "With the Based Brett Zebec Instant Card, say goodbye to fees and hello to freedom. Experience the thrill of keeping all your cash, because here, we believe in unfiltered financial empowerment.",
    icon: <ZeroFeeIcon className="text-[#1E50FF]" />,
    span: "sm:col-span-2",
  },
  {
    title: "Why wait? Lightning-Fast, Brett-Style",
    desc: "The Based Brett Zebec Instant Card delivers your transactions at the speed of light, ensuring you're always ahead of the game. Fast, fearless, and unapologetically fierce – just like you.",
    icon: <RocketIcon className="text-[#1E50FF]" />,
    span: "sm:col-span-2",
  },
  {
    title: "Command Your Capital: Spend Boldly, Spend Wisely",
    desc: "Your money, your rules. The Based Brett Zebec Instant Card gives you the reigns to your financial kingdom, with secure, non-custodial freedom that's as uncompromising as you are.",
    icon: <CartIcon className="text-[#1E50FF]" />,
    span: "sm:col-span-2",
  },
  {
    title: "Worldwide Warrior: Accepted Everywhere Your Journey Takes You",
    desc: "Take on the world with the Based Brett Zebec Instant Card. Accepted globally, it's the perfect companion for the audacious adventurer in you, ready to conquer new horizons.",
    icon: <WorldIcon className="text-[#1E50FF]" />,
    span: "sm:col-span-2",
  },
];

const BrettInstantCardRocks = () => {
  return (
    <>
      <div className="web-padding pt-16 pb-10 md:pt-[100px] md:pb-16">
        <div className="grid sm:grid-cols-2 gap-6 xl:gap-10 mb-6">
          <div className="">
            <div className="mb-5 sm:max-w-[750px] text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
              The Brett Revolution: <br />
              <span className="text-[#1E50FF]">Spend with Swagger!</span>
            </div>
          </div>
          <div className="lg:pl-32">
            <p className="text-base md:text-[22px] leading-normal text-[#32285A]">
              Discover the Based Brett Zebec Instant Card, where every
              transaction is a statement of boldness and rebellion. This isn't
              just a card; it's your ticket to a lifestyle where boundaries are
              pushed, and conventions are challenged
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 xl:gap-10">
          {cards.map((data, index) => {
            const { icon, title, desc, span } = data;
            return (
              <div
                key={index}
                className={`bg-[#F8F8FF] text-[#32285A] rounded-[20px] p-6 xl:p-10 ${span}`}
              >
                <div>{icon}</div>
                <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8">
                  {title}
                </h1>
                <div className="mt-5 text-[#6E6EA0]">{desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BrettInstantCardRocks;
