import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ZebecFreedom = () => {
  const { t } = useTranslation();

  return (
    <div className="my-24">
      <h1 className="uppercase text-zebec-primary font-normal text-4xl md:text-5xl lg:text-[60px] lg:leading-[65px]">
        <span>{t("zebecBlack:3rd_section.h2_1")}</span> <br />
        <div>
          {t("zebecBlack:3rd_section.h2_2")}{" "}
          <span
            className="bg-clip-text text-transparent"
            style={{
              backgroundImage:
                " linear-gradient(-90deg, #c8a0fa -20.38%, #1e50ff 103.77%)",
            }}
          >
            {t("zebecBlack:3rd_section.h2_its")} <br />
            {t("zebecBlack:3rd_section.h2_3")}
          </span>
        </div>
      </h1>
      <p className="md:ml-auto mt-4 lg:mt-0 max-w-2xl text-[22px] leading-8 tracking-[-1%] text-zebec-primary">
        {t("zebecBlack:3rd_section.desc")}
      </p>
      <p className="md:ml-auto mt-2 max-w-2xl text-[22px] leading-8 tracking-[-1%] text-zebec-primary">
        {t("zebecBlack:3rd_section.desc2")}{" "}
        <span>
          <Link
            to={"/partner"}
            className="gradient-text gradient-text-border-b"
          >
            {t("zebecBlack:3rd_section.lets_talk")}
          </Link>
        </span>
      </p>
    </div>
  );
};

export default ZebecFreedom;
