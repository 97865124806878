import React from "react";
import { ZebecBlackCardImg, ZebecInstantCardImg } from "../../assets/images";
import { Link } from "react-router-dom";
import * as Icons from "../../assets/icons";
import { Button } from "../shared/Button";

const Cards = () => {
  return (
    <div className="font-ppmori relative">
      <div className="h-full w-full rounded-[30px] bg-no-repeat bg-cover partners-cards-bg text-center pt-20 pb-10 px-10 lg:px-20">
        <h2 className="text-3xl font-normal md:text-4xl lg:text-[50px] lg:leading-[50px] text-white">
          <span className="text-[#5CFFD1]">Zebec Cards</span>
          <br />
          Limitless or Lightning Fast? Your call.
        </h2>
        <div className="grid md:grid-cols-2 items-center mt-12 gap-6 max-w-4xl mx-auto">
          <div className="">
            <p className="text-zebec-primary-light text-[22px] leading-[33px] -tracking-[1%] text-center mb-2 hidden md:block">
              Fast + Private
            </p>
            <img
              src={ZebecInstantCardImg}
              className="w-auto max-h-72 mx-auto"
              alt=""
            />
            <p className="text-zebec-primary-light text-[22px] leading-[33px] -tracking-[1%] text-center mt-2 hidden md:block">
              Zebec Card
            </p>
          </div>
          <div className="">
            <p className="text-zebec-primary-light text-[22px] leading-[33px] -tracking-[1%] text-center mb-2 hidden md:block">
              Reloadable + Limitless
            </p>
            <img
              src={ZebecBlackCardImg}
              className=" w-auto max-h-72 mx-auto"
              alt=""
            />
            <p className="text-zebec-primary-light text-[22px] leading-[33px] -tracking-[1%] text-center mt-2 hidden md:block">
              Zebec Black Card
            </p>
          </div>
        </div>
        <Link to={"/"} className="">
          <Button
            title={"Zebec Cards"}
            className="green-button-border !text-sm lg:!text-base rounded-full text-primary m-auto mt-6"
            startIcon={
              <div
                className={
                  "w-10 h-10 rounded-full bg-primary grid place-content-center"
                }
              >
                <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
              </div>
            }
          />
        </Link>
      </div>
    </div>
  );
};

export default Cards;
