import React from "react";
import { useTranslation } from "react-i18next";

const PowerOfChoice = () => {
  const { t } = useTranslation();

  return (
    <div className="my-24 flex flex-col gap-6 md:flex-row justify-between">
      <h2 className="text-4xl md:text-5xl lg:text-[60px] lg:leading-[65px] font-normal text-zebec-primary">
        {t("zebecBlack:2nd_section.h2_1")} <br />
        {t("zebecBlack:2nd_section.h2_2")} <br />
        {t("zebecBlack:2nd_section.h2_3")}
      </h2>
      <p className="text-zebec-primary md:w-[49%] text-[22px] leading-[33px] -tracking-[1%]">
        {t("zebecBlack:2nd_section.desc")}
      </p>
    </div>
  );
};

export default PowerOfChoice;
