import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Notice from "./Notice";
import { SHOW_NOTICE } from "../constants/constants";

const Layout = ({ children }) => {
  return (
    <div className="font-ppmori">
      {SHOW_NOTICE && <Notice />}
      <Navbar />
      <main className="">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
