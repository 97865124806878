import React from "react";
import HeroSection from "./integrate/HeroSection";
import ZebecFreedom from "./integrate/ZebecFreedom";
import PowerOfChoice from "./integrate/PowerOfChoice";
import ZebecCards from "./integrate/ZebecCards";

const Home = () => {
  return (
    <div className="web-container">
      <HeroSection />
      <PowerOfChoice />
      <ZebecCards />
      <ZebecFreedom />
    </div>
  );
};

export default Home;
