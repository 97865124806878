import React from "react";
import HeroSection from "./integrate/HeroSection";
import BrettInstantCardRocks from "./integrate/BrettInstantCardRocks";
import BrettRevolutionFloks from "./integrate/BrettRevolutionFloks";

const Brett = () => {
  return (
    <>
      <div>
        <HeroSection />
        <BrettInstantCardRocks />
        <BrettRevolutionFloks />
      </div>
    </>
  );
};

export default Brett;
