import React from "react";
import { PartnerHeroBgImg } from "../../assets/images/partner";
import { Button } from "../shared/Button";
import * as Icons from "../../assets/icons";

const HeroSection = () => {
  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="relative">
          <div className=" overflow-hidden rounded-[30px]">
            <img
              src={PartnerHeroBgImg}
              className="w-full h-full object-right object-cover absolute top-0 right-0 hidden lg:block rounded-[30px]"
              alt=""
            />
            <img
              src={PartnerHeroBgImg}
              className="w-full object-cover object-bottom absolute top-0 right-0 lg:hidden rounded-[30px] h-full"
              alt=""
            />
            <div className="relative z-[1] flex flex-col lg:flex-row lg:items-center justify-between h-full">
              <div className="pt-20 pb-10 px-10 lg:px-20 flex flex-col w-full justify-center lg:w-2/3">
                <h1 className="text-3xl md:text-4xl lg:text-[60px] lg:leading-[60px] text-[#5CFFD1]">
                  Tailored Crypto Cards, <br /> Built for Your Brand
                </h1>
                <p className="text-lg md:text-[22px] lg:text-[28px] leading-normal text-white mt-5 max-w-[536px]">
                  Over 70+ tokens—from blue-chip OGs to the hottest memes—across
                  all leading networks have launched bespoke cards with Zebec.
                </p>
                <div>
                  <a
                    href="https://telegram.me/jjortiz15"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <Button
                      title={"Get in Touch"}
                      className="green-button-border my-5 md:mb-0 md:mt-10 text-sm lg:text-base rounded-full text-primary"
                      startIcon={
                        <div
                          className={
                            "w-10 h-10 rounded-full bg-primary grid place-content-center"
                          }
                        >
                          <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                        </div>
                      }
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
