import React from "react";
import HeroSection from "./integrate/HeroSection";
import ZebecEasySetup from "./integrate/ZebecEasySetup";
import FAQs from "./integrate/FAQs";

const BlackCardFaqs = () => {
  return (
    <>
      <div className="web-container">
        <HeroSection />
        <ZebecEasySetup />
        <FAQs />
      </div>
    </>
  );
};

export default BlackCardFaqs;
