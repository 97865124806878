import React from "react";
import { Link } from "react-router-dom";
import {
  ZBCPromoCoinBgImg,
  ZBCPromoVisaCardBgImg,
} from "../../../assets/images";

const ZBCPromoEndCards = () => {
  return (
    <>
      <div className="mt-24 web-padding">
        <div className="grid sm:grid-cols-2 justify-between gap-10">
          <div className="text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
            Get Rewarded in ZBC Style:
            <br />
            <span className="text-primary">Spend Big, Earn Bigger</span>
          </div>
          <div className="text-lg xl:text-[21px] leading-[120%] lg:mr-20 mt-10 sm:mt-0">
            Welcome to the days of ZBC Rewards, where your everyday spending
            unlocks extraordinary rewards! With the Zebec Instant Card, your
            purchases transform into profitable pleasures.
          </div>
        </div>
      </div>
      <div className="web-padding my-16 md:my-24">
        <div className="grid md:grid-cols-3 gap-5">
          <div className="relative promo-card-1 p-8 md:p-10 rounded-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-[48px] lg:leading-[48px] xl:text-[65px] xl:leading-[65px] text-dark-black">
              $1K
            </h1>
            <h1 className="text-[24px] md:text-[26px] lg:text-[29px] lg:leading-[34px] text-dark-black">
              Booster
            </h1>
            <p className="text-base leading-6 text-dark-black mt-8">
              Fuel Your Zebec Instant Card with $1000 in one month and receive a
              2% ZBC Bonus, effortlessly deposited to your Solana wallet. Spend
              smart, earn smarter!
            </p>
            <div className="absolute top-0 right-8 bg-dark-black text-white rounded-b-2xl pt-6 px-3.5 pb-2.5">
              <h1 className="text-3xl md:text-5xl lg:leading-[40px]">
                2 <span className="text-base opacity-80">%</span>
              </h1>
              <p className="text-sm opacity-80">ZBC Bonus</p>
            </div>
          </div>
          <div className="relative promo-card-2 p-8 md:p-10 rounded-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-[48px] lg:leading-[48px] xl:text-[65px] xl:leading-[65px] text-dark-black">
              $5K
            </h1>
            <h1 className="text-[24px] md:text-[26px] lg:text-[29px] lg:leading-[34px] text-dark-black">
              Fiesta
            </h1>
            <p className="text-base leading-6 text-dark-black mt-8">
              Join the fiesta of finances! Spend $5000 with Zebec Instant in a
              single month and celebrate as 2.5% of your expenditure comes back
              to you in ZBC. The party just got profitable!
            </p>
            <div className="absolute top-0 right-7 bg-dark-black text-white rounded-b-2xl pt-6 px-2 pb-2.5">
              <h1 className="text-3xl md:text-5xl lg:leading-[40px]">
                2.5 <span className="text-base opacity-80">%</span>
              </h1>
              <p className="text-sm opacity-80">ZBC Bonus</p>
            </div>
          </div>
          <div className="relative promo-card-3 p-8 md:p-10 rounded-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-[48px] lg:leading-[48px] xl:text-[65px] xl:leading-[65px] text-dark-black">
              $10K
            </h1>
            <h1 className="text-[24px] md:text-[26px] lg:text-[29px] lg:leading-[34px] text-dark-black">
              Triumph
            </h1>
            <p className="text-base leading-6 text-dark-black mt-8">
              Ascend to the apex of spending power with the 10K Triumph! Reach a
              monumental $10,000 in Zebec spending within a month and bask in a
              royal 5% reward in ZBC. Your grand expenses now bring even grander
              earnings!
            </p>
            <div className="absolute top-0 right-8 bg-dark-black text-white rounded-b-2xl pt-6 px-3.5 pb-2.5">
              <h1 className="text-3xl md:text-5xl lg:leading-[40px]">
                5 <span className="text-base opacity-80">%</span>
              </h1>
              <p className="text-sm opacity-80">ZBC Bonus</p>
            </div>
          </div>
        </div>
      </div>
      <div className="web-padding mb-16 md:mb-24">
        <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
          {/* Have Questions */}
          <div className="relative flex flex-col justify-between lg:col-span-3 p-8 md:p-10 lg:p-[60px] rounded-[30px] overflow-hidden">
            <div className="">
              <p className="max-w-[180px] sm:max-w-[400px] mb-5 text-[25px] md:text-[40px] leading-[130%]">
                Are you ready to spend big and earn bigger?
              </p>
              <p className="max-w-[226px] sm:max-w-[400px] mb-12 text-[16px] md:text-[22px] leading-[130%]">
                Load your Zebec Instant Card today and let your journey of
                lavish rewards begin!
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <a
                href="https://app.card.zebec.io/"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full">
                  Get Instant Now
                </button>
              </a>
            </div>
            <img
              src={ZBCPromoVisaCardBgImg}
              className="absolute inset-0 object-cover w-full h-full -z-10"
              alt=""
            />
          </div>
          {/* Got Ideas */}
          <div className="relative col-span-1 flex flex-col justify-between lg:col-span-2 p-8 md:p-10 lg:p-[60px] rounded-[30px] overflow-hidden">
            <div className="">
              <p className="max-w-[200px] sm:max-w-[280px] mb-5 text-[25px] md:text-[40px] leading-[130%]">
                Have Questions?
              </p>
              <p className="max-w-[226px] sm:max-w-[338px] mb-12 text-[16px] md:text-[22px] leading-[130%]">
                Explore More about Zebec Instant
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <Link to="/" className="">
                <button className="bg-[#141414] text-white text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full">
                  Find Answers
                </button>
              </Link>
            </div>
            <img
              src={ZBCPromoCoinBgImg}
              className="absolute inset-0 object-cover w-full h-full -z-10"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ZBCPromoEndCards;
