import React from "react";
import {
  RocketIcon,
  ShieldIcon,
  WorldIcon,
  ZeroFeeIcon,
} from "../../../assets/icons";

const cards = [
  {
    title: "Instant Power up",
    desc: "Just a few taps and your card is ready – it works incredible!",
    icon: <RocketIcon className="text-primary" />,
    span: "sm:col-span-1",
  },
  {
    title: "Your Passport to Everywhere",
    desc: "Accepted globally. Whether you're in a downtown cafe or an exotic beach, you're good to go",
    icon: <WorldIcon className="text-primary" />,
    span: "sm:col-span-1",
  },
  {
    title: "Keep Command of Your Crypto",
    desc: "It's your crypto kingdom, and you're the ruler. Zebec Instant is the first truly non-custodial and multi-chain crypto card",
    icon: <ShieldIcon />,
    span: "sm:col-span-2",
  },
];

const ZebecInstantRealDeal = () => {
  return (
    <>
      <div className="web-padding pt-16 pb-10 md:pt-[100px] md:pb-16">
        <div className="grid sm:grid-cols-2 gap-6 xl:gap-10 mb-6">
          <div className="sm:max-w-[750px] text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
            Why the Zebec Instant is the Real Deal?
          </div>
          <div
            className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10`}
          >
            <div>
              <ZeroFeeIcon className="text-[#08E0A3]" />
            </div>
            <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
              Zero Fees, Zero Drama
            </h1>
            <div className="mt-5">
              This card is all about keeping it simple and real
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 xl:gap-10">
          {cards.map((data, index) => {
            const { icon, title, desc, span } = data;
            return (
              <div
                key={index}
                className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10 ${span}`}
              >
                <div>{icon}</div>
                <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
                  {title}
                </h1>
                <div className="mt-5">{desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ZebecInstantRealDeal;
