import React from "react";
import HeroSection from "./integrate/HeroSection";
import IndustryFirst from "./integrate/IndustryFirst";
import ZebecEasySetup from "./integrate/ZebecEasySetup";
import Gift from "./integrate/Gift";
import FAQs from "./integrate/FAQs";
// import ComingSoonTitle from "./integrate/ComingSoonTitle";

const ZebecSilver = () => {
  return (
    <div className="web-container">
      {/* <ComingSoonTitle /> */}
      <HeroSection />
      <IndustryFirst />
      <ZebecEasySetup />
      <Gift />
      <FAQs />
    </div>
  );
};

export default ZebecSilver;
