import React from "react";
import ZBCPromoEndCards from "./integrate/ZBCPromoEndCards";
import HeroSection from "./integrate/HeroSection";

const ZBCIntegrationPromo = () => {
  return (
    <>
      <div>
        <HeroSection />
        <ZBCPromoEndCards />
      </div>
    </>
  );
};

export default ZBCIntegrationPromo;
