import React from "react";
import { Helmet } from "react-helmet";

const SchemaOpenGraph = () => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: "https://card.zebec.io/",
    name: "Zebec Cards",
  };

  return (
    <Helmet>
      <meta property="og:title" content="Zebec Cards" />
      <meta
        property="og:description"
        content="Decentralized infrastructure network for seamless flows of real-world value."
      />
      <meta
        property="og:image"
        content="https://pbs.twimg.com/profile_images/1773695810464841728/JJV-0p2A_400x400.jpg"
      />
      <meta property="og:url" content="https://www.card.zebec.io/" />
      <meta property="og:type" content="website" />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export default SchemaOpenGraph;
