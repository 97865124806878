import React from "react";
import { OpenLegendImg, OpenLegendMobileVidImg } from "../../../assets/images";
import OpenLegendVideo from "../../layout/OpenLegendVideo";

const LegendVideo = ({ openLegendVideo, setOpenLegendVideo }) => {
  return (
    <>
      <div className="web-padding mt-10 md:mt-16">
        <div className="relative">
          <div
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => setOpenLegendVideo(true)}
          >
            <svg
              width="63"
              height="70"
              viewBox="0 0 63 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.6347 28.9536C63.2652 31.6547 63.2652 38.3453 58.6347 41.0465L10.9557 68.8592C6.28905 71.5814 0.42857 68.2153 0.428571 62.8127L0.428573 7.18725C0.428573 1.78472 6.28907 -1.58138 10.9557 1.14081L58.6347 28.9536Z"
                fill="#08E0A3"
              />
            </svg>
          </div>
          <img src={OpenLegendImg} className="w-full hidden md:block" alt="" />
          <img
            src={OpenLegendMobileVidImg}
            className="w-full md:hidden"
            alt=""
          />
        </div>
      </div>
      {openLegendVideo && (
        <OpenLegendVideo setOpenVideoLoop={setOpenLegendVideo} />
      )}
    </>
  );
};

export default LegendVideo;
