import React from "react";
import HeroSection from "./integrate/HeroSection";
import BonkInstantCardRocks from "./integrate/BurrrdInstantCardRocks";
import BonkRevolutionFloks from "./integrate/BurrrdRevolutionFloks";
import BonkMap from "./integrate/BurrrdMap";

const Burrrd = () => {
  return (
    <>
      <div>
        <HeroSection />
        <BonkInstantCardRocks />
        <BonkMap />
        <BonkRevolutionFloks />
      </div>
    </>
  );
};

export default Burrrd;
