import React from "react";
import {
  LightningIcon,
  RocketIcon,
  ShieldIcon,
  WorldIcon,
  ZeroFeeIcon
} from "../../../assets/icons";

const cards = [
  {
    title: "Aim High, Spend Wise",
    desc: "With Zebec Instant you can spread your wings and spend some of your “hard earned” memes",
    icon: <RocketIcon className="text-primary" />,
    span: "sm:col-span-1"
  },
  {
    title: "Zero Fees, All in Good Fun",
    desc: "Pecking at Perks: Enjoy zero fees and a sky-high convenience with Zebec Instant. It's like having a chirpy assistant for your wallet!",
    icon: <ZeroFeeIcon className="text-primary" />,
    span: "sm:col-span-1"
  },
  {
    title: "Your Coin, Yout Nest Egg",
    desc: "Non-custodial means you're the sole overlord of your crypto coop. Plot your financial escapades with Zebec Instant. The Burrrd brigade cheers you on!",
    icon: <ShieldIcon className="text-primary" />,
    span: "sm:col-span-1"
  },
  {
    title: "Burrrd's Eye View",
    desc: "Enjoy global acceptance and total control over your finances. Wherever you land, Zebec Instant gives you the power",
    icon: <WorldIcon className="text-primary" />,
    span: "sm:col-span-1"
  }
];

const BurrrdInstantCardRocks = () => {
  return (
    <>
      <div className="web-padding pt-16 pb-10 md:pt-[100px] md:pb-16">
        <div className="grid sm:grid-cols-2 gap-6 xl:gap-10 mb-6">
          <div className="">
            <div className="mb-5 sm:max-w-[750px] text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
              Why Zebec Instant Card Rocks!
            </div>
            <p className="mb-5 sm:max-w-[750px] text-zebec-black text-base xl:text-[22px] xl:leading-[130%]">
              You asked for a card that lets you spend BURRRD like cold, hard
              cash? Meet The Zebec Instant Card:
            </p>
          </div>
          <div
            className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10`}
          >
            <div>
              <LightningIcon />
            </div>
            <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
              Unleash the Savage in an Instant
            </h1>
            <div className="mt-5">
              Need a quick boost? Zebec Instant zaps your transactions with the
              speed of an angry bird. Fast, furious, and fun!
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 xl:gap-10">
          {cards.map((data, index) => {
            const { icon, title, desc, span } = data;
            return (
              <div
                key={index}
                className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10 ${span}`}
              >
                <div>{icon}</div>
                <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
                  {title}
                </h1>
                <div className="mt-5">{desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BurrrdInstantCardRocks;
