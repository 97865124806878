import React from "react";
import HeroSection from "./HeroSection";
import WhyPartner from "./WhyPartner";

const Partner = () => {
  return (
    <>
      <HeroSection />
      <WhyPartner />
    </>
  );
};

export default Partner;
