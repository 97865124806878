import React, { useState } from "react";
import { OpenLegendImg, OpenLegendMobileVidImg } from "../../../assets/images";
import OpenLegendVideo from "../../layout/OpenLegendVideo";
import { Link } from "react-router-dom";
import { SillyDragonJoinTribeIconImg } from "../../../assets/images/partners";

const SillyDragonRevolutionFloks = () => {
  const [openLegendVideo, setOpenLegendVideo] = useState(false);
  return (
    <>
      <div className="web-padding mt-10">
        <div className="relative">
          <div
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => setOpenLegendVideo(true)}
          >
            <svg
              width="63"
              height="70"
              viewBox="0 0 63 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.6347 28.9536C63.2652 31.6547 63.2652 38.3453 58.6347 41.0465L10.9557 68.8592C6.28905 71.5814 0.42857 68.2153 0.428571 62.8127L0.428573 7.18725C0.428573 1.78472 6.28907 -1.58138 10.9557 1.14081L58.6347 28.9536Z"
                fill="#08E0A3"
              />
            </svg>
          </div>
          <img src={OpenLegendImg} className="w-full hidden md:block" alt="" />
          <img
            src={OpenLegendMobileVidImg}
            className="w-full md:hidden"
            alt=""
          />
        </div>
      </div>
      {openLegendVideo && (
        <OpenLegendVideo setOpenVideoLoop={setOpenLegendVideo} />
      )}
      <div className="web-padding mt-8 mb-16 md:mb-24">
        <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
          {/* Have Questions */}
          <div className="relative silly-dragon-tribe flex flex-col justify-between lg:col-span-3 p-8 md:p-10 lg:p-[60px] rounded-[30px]">
            <div className="">
              <p className="max-w-[385px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-white">
                Join the Silly Dragon Tribe!
              </p>
              <p className="max-w-[338px] mb-12 text-[16px] md:text-[16px] leading-[130%]  text-white">
                Ready for an adventure? Sign up for your Zebec Instant Card
                today and let Silly Dragon lead you to a land of financial
                wisdom and wonder!
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <a
                href="https://app.card.zebec.io/"
                target="_blank"
                rel="noreferrer"
                className="w-auto"
              >
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-auto">
                  Get Instant Now
                </button>
              </a>
            </div>
            <img
              src={SillyDragonJoinTribeIconImg}
              className="absolute bottom-0 right-10 w-[80px] h-[80px] sm:w-[120px] sm:h-[120px] lg:w-[160px] lg:h-[160px] -translate-y-10"
              alt=""
            />
          </div>
          {/* Got Ideas */}
          <div className="silly-crave-more col-span-1 flex flex-col justify-between lg:col-span-2 p-8 md:p-10 lg:p-[60px] rounded-[30px]">
            <div className="">
              <p className="max-w-[385px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-white">
                Crave More Secrets?
              </p>
              <p className="max-w-[338px] mb-12 text-[16px] md:text-[16px] leading-[130%]  text-white">
                Hungry for more enchanting tales? Dive deeper into the world of
                Zebec Instant
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <Link to="/" className="">
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full">
                  Find Answers
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SillyDragonRevolutionFloks;
