import React from "react";
import { Link } from "react-router-dom";
import {
  BrettMobileClaimYourCard,
  BrettWebClaimYourCard,
} from "../../../assets/images/partners";

const BrettRevolutionFloks = () => {
  return (
    <>
      <div className="web-padding mt-8 mb-16 md:mb-24">
        <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
          {/* Claim Your Card */}
          <div className="bg-[#1E50FF] relative flex flex-col justify-between lg:col-span-3 pl-8 md:pl-10 pb-8 md:pb-10 lg:p-[60px] rounded-[30px]">
            <img
              src={BrettMobileClaimYourCard}
              className="lg:hidden rounded-tr-[30px]"
              alt=""
            />
            <div className="relative z-20">
              <p className="max-w-[385px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-white">
                Claim Your Card: Join the Troops Now!
              </p>
              <p className="text-base text-white max-w-[385px]">
                Ready to redefine the way you spend? Apply for your Based Brett
                Zebec Instant Card today and step into a world where your
                financial power is unleashed.
              </p>
            </div>
            <div className="flex items-center gap-x-2.5 relative z-20 mt-6">
              <a
                href="https://app.card.zebec.io/"
                target="_blank"
                rel="noreferrer"
                className="w-full sm:w-auto"
              >
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                  Get Instant Now
                </button>
              </a>
            </div>
            <div className="">
              <img
                src={BrettWebClaimYourCard}
                className="hidden lg:block absolute max-h-[300px] lg:max-h-[350px] z-[10] bottom-0 right-0"
                alt=""
              />
            </div>
          </div>
          {/* Crave More */}
          <div className="brett-crave-more col-span-1 flex flex-col lg:col-span-2 p-8 md:p-10 lg:p-[60px] rounded-[30px]">
            <div className="">
              <p className="max-w-[385px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-white">
                Crave More details?
              </p>
              <p className="max-w-[338px] mb-12 text-[16px] md:text-[22px] leading-[130%]  text-white">
                Dive deeper into the world of the Zebec Instant Card. Explore
                the archives and manuals here
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <Link to="/" className="w-full sm:w-auto">
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                  Read More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrettRevolutionFloks;
