import React, { useState } from "react";
import { TickIcon } from "../../../assets/icons";
import { Line, ReferralEasySetupImg } from "../../../assets/images";
import HowToVideoLoop from "../../layout/HowToVideoLoop";
import * as Videos from "../../../assets/video/index";

const setup = [
  {
    desc: "Connect your Phantom, MetaMask or Binance wallet",
  },
  {
    desc: "Load up some USDC, you know the drill",
  },
  {
    desc: "Buy Zebec Instant Prepaid with USDC balance",
  },
  {
    desc: "Choose e-card or physical card",
  },
  {
    desc: "A Voilà!  You’re ready to roll",
  },
];

const ReferralEasySetup = () => {
  const [openVideoLoop, setOpenVideoLoop] = useState(false);
  return (
    <>
      {openVideoLoop ? (
        <HowToVideoLoop
          setOpenVideoLoop={setOpenVideoLoop}
          video={Videos.HowToUseVid}
        />
      ) : null}
      <div className="w-full web-padding max-w-[1440px] mx-auto  md:flex items-center gap-5 lg:gap-10 my-12 lg:my-24">
        <div>
          <img
            src={ReferralEasySetupImg}
            className="w-[80%] mx-auto lg:w-[95%]"
            alt=""
          />
        </div>
        <div className="mt-10 sm:mt-0 lg:pl-[10%]">
          <h1 className="text-[25px] md:text-[40px] leading-[130%]">
            It's Ridiculously Easy!
          </h1>
          <div className="mt-10 space-y-10 relative">
            <img
              src={Line}
              alt=""
              className="absolute left-[19px] top-12 h-[120px] z-[-1]"
            />
            <img
              src={Line}
              alt=""
              className="absolute left-[19px] bottom-12 h-[120px] z-[-1]"
            />
            {setup.map((data, index) => {
              return (
                <div className="flex items-center gap-4" key={index}>
                  <div className="w-[40px] h-[40px] rounded-full bg-primary grid place-content-center flex-shrink-0">
                    <TickIcon />
                  </div>
                  <div>
                    <p className="max-w-[329px]">{data.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralEasySetup;
