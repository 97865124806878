import React from "react";
import { BrettHeroImg } from "../../../assets/images/partners";

const HeroSection = () => {
  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="relative">
          <div className="brett-hero-bg bg-[#2051FF] md:h-[calc(100vh-160px)] overflow-hidden rounded-[30px] md:flex md:bg-right bg-bottom bg-no-repeat">
            <div className="py-10 px-10 lg:px-20 relative z-[1] flex flex-col lg:flex-row gap-10 w-full md:items-start lg:items-center  pb-10">
              <div className="order-2 lg:order-1 flex flex-col justify-center w-full lg:w-1/2">
                <h1 className="text-3xl md:text-4xl lg:text-[50px] lg:leading-[60px] text-white max-w-[586px]">
                  <span className="text-[#5AFFD2]">
                    Unleash the Power of Pay:
                  </span>{" "}
                  The Brett Zebec Instant Card!
                </h1>
                <p className="text-base leading-6 text-white max-w-md mt-5">
                  Elevate Your Spending Game with the Ultimate Card for the Bold
                  and the Brave!
                </p>
                <div className="flex items-center gap-x-2.5 mt-10">
                  <a
                    href="https://app.card.zebec.io/"
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                      Get Instant Now
                    </button>
                  </a>
                  {/* <a
                    href="https://app.card.zebec.io/"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <button className="border border-primary text-primary transition-all duration-300 hover:bg-primary hover:text-darkish text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full ">
                      Refer Friends
                    </button>
                  </a> */}
                </div>
              </div>
              <div className="order-1 lg:order-2 lg:w-1/2 lg:self-end lg:mb-10">
                <img
                  src={BrettHeroImg}
                  className="rounded-[20px] md:rounded-[30px] md:max-h-[530px] md:h-full w-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
