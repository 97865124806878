import React from "react";
import {
  ReferralMobileSpendingGameBgImg,
  ReferralSpendingGameImg,
} from "../../../assets/images";
import { LEGEND_PARAMS } from "../legend-params";
import { Link, useParams } from "react-router-dom";
import {
  BurrrdGotIdeasBg,
  BurrrdMobileGotIdeasBg,
  BurrrdMobileSpendingGameBg,
  BurrrdSpendingGameBg,
  CTGotIdeasBg,
  CTMobileGotIdeasBg,
  ChrisGotIdeasBg,
  ChrisMobileGotIdeasBg,
  ChrisMobileSpendingGameBg,
  ChrisSpendingGameBg,
  CowboyGotIdeasBg,
  CowboyMobileGotIdeasBg,
  CowboyMobileSpendingGameBg,
  CowboySpendingGameBg,
  GameshoeGotIdeasBg,
  GameshoeMobileGotIdeasBg,
  GameshoeMobileSpendingGameBg,
  GameshoeSpendingGameBg,
  IcedGotIdeasBg,
  IcedMobileGotIdeasBg,
  IcedMobileSpendingGameBg,
  IcedSpendingGameBg,
  JakeyGotIdeasBg,
  JakeyMobileGotIdeasBg,
  JakeyMobileSpendingGameBg,
  JakeySpendingGameBg,
  JoeGotIdeasBg,
  JoeMobileGotIdeasBg,
  JoeMobileSpendingGameBg,
  JoeSpendingGameBg,
  SugarGotIdeasBg,
  SugarMobileGotIdeasBg,
  SugarMobileSpendingGameBg,
  SugarSpendingGameBg,
} from "../../../assets/images/legend";

const SpendingGame = ({ setOpenLegendVideo }) => {
  const params = useParams();

  const spendingGameBg =
    params.id === LEGEND_PARAMS.COWBOY
      ? CowboySpendingGameBg
      : params.id === LEGEND_PARAMS.ICED
      ? IcedSpendingGameBg
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? GameshoeSpendingGameBg
      : params.id === LEGEND_PARAMS.JOE
      ? JoeSpendingGameBg
      : params.id === LEGEND_PARAMS.SUGAR
      ? SugarSpendingGameBg
      : params.id === LEGEND_PARAMS.BURRRD
      ? BurrrdSpendingGameBg
      : params.id === LEGEND_PARAMS.JAKEY || params.id === LEGEND_PARAMS.CT
      ? JakeySpendingGameBg
      : params.id === LEGEND_PARAMS.CHRIS
      ? ChrisSpendingGameBg
      : ReferralSpendingGameImg;

  const spendingGameMobileBg =
    params.id === LEGEND_PARAMS.COWBOY
      ? CowboyMobileSpendingGameBg
      : params.id === LEGEND_PARAMS.ICED
      ? IcedMobileSpendingGameBg
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? GameshoeMobileSpendingGameBg
      : params.id === LEGEND_PARAMS.JOE
      ? JoeMobileSpendingGameBg
      : params.id === LEGEND_PARAMS.SUGAR
      ? SugarMobileSpendingGameBg
      : params.id === LEGEND_PARAMS.BURRRD
      ? BurrrdMobileSpendingGameBg
      : params.id === LEGEND_PARAMS.JAKEY || params.id === LEGEND_PARAMS.CT
      ? JakeyMobileSpendingGameBg
      : params.id === LEGEND_PARAMS.CHRIS
      ? ChrisMobileSpendingGameBg
      : ReferralMobileSpendingGameBgImg;

  const gotIdeasBg =
    params.id === LEGEND_PARAMS.COWBOY
      ? CowboyGotIdeasBg
      : params.id === LEGEND_PARAMS.ICED
      ? IcedGotIdeasBg
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? GameshoeGotIdeasBg
      : params.id === LEGEND_PARAMS.JOE
      ? JoeGotIdeasBg
      : params.id === LEGEND_PARAMS.SUGAR
      ? SugarGotIdeasBg
      : params.id === LEGEND_PARAMS.BURRRD
      ? BurrrdGotIdeasBg
      : params.id === LEGEND_PARAMS.JAKEY
      ? JakeyGotIdeasBg
      : params.id === LEGEND_PARAMS.CT
      ? CTGotIdeasBg
      : params.id === LEGEND_PARAMS.CHRIS
      ? ChrisGotIdeasBg
      : IcedGotIdeasBg;

  const gotIdeasMobileBg =
    params.id === LEGEND_PARAMS.COWBOY
      ? CowboyMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.ICED
      ? IcedMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? GameshoeMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.JOE
      ? JoeMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.SUGAR
      ? SugarMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.BURRRD
      ? BurrrdMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.JAKEY
      ? JakeyMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.CT
      ? CTMobileGotIdeasBg
      : params.id === LEGEND_PARAMS.CHRIS
      ? ChrisMobileGotIdeasBg
      : IcedMobileGotIdeasBg;

  const twitterHandleLink =
    params.id === LEGEND_PARAMS.COWBOY
      ? `https://twitter.com/bullish_cowbo`
      : params.id === LEGEND_PARAMS.ICED
      ? `https://twitter.com/IcedKnife`
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? `https://twitter.com/0xGumshoe`
      : params.id === LEGEND_PARAMS.JOE
      ? `https://x.com/joemccann?s=11&t=4toTZ_GGrALEih___kWsGg`
      : params.id === LEGEND_PARAMS.SUGAR
      ? `https://x.com/suganarium?s=11`
      : params.id === LEGEND_PARAMS.BURRRD
      ? `https://x.com/suganarium?s=11`
      : params.id === LEGEND_PARAMS.JAKEY
      ? `https://twitter.com/SolJakey`
      : params.id === LEGEND_PARAMS.CT
      ? `https://twitter.com/cripto_t`
      : params.id === LEGEND_PARAMS.HERNAN
      ? `https://x.com/holdmybirra?s=21`
      : params.id === LEGEND_PARAMS.METASAL
      ? `https://twitter.com/metasal_`
      : params.id === LEGEND_PARAMS.UPROCK
      ? `https://twitter.com/uprockcom`
      : ``;

  return (
    <>
      <div className="mt-24 mb-5 web-padding ">
        <div className="relative w-full rounded-[30px]">
          <img
            src={spendingGameBg}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-[30px] hidden md:block"
          />
          <img
            src={spendingGameMobileBg}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-[30px] md:hidden"
          />
          {/* <img src={Gift2Image} alt="" className="block sm:hidden mx-auto" /> */}
          <div className="relative py-[120px] px-6 z-[1] w-full h-full flex flex-col items-center justify-center">
            {/* <h1 className="text-[40px] leading-[50px] text-white block sm:hidden max-w-[240px] mx-auto">
            Ready to flip the script on your spending game?
          </h1> */}
            <h1 className="text-[40px] lg:text-[50px] xl:text-[60px] text-white text-center max-w-[968px] mb-5">
              Ready to flip the script on your spending game?
            </h1>
            <p className="text-lg md:text-xl lg:text-3xl text-white mb-6 md:mb-10">
              Let's roll!
            </p>
            <div className="flex flex-col sm:flex-row w-[90%] justify-center items-center gap-2.5">
              <a
                href={`https://app.card.zebec.io?ref=${params.id}`}
                target="_blank"
                rel="noreferrer"
                className="w-full sm:w-auto"
              >
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                  Get Zebec Instant
                </button>
              </a>
              <a
                href={`https://app.card.zebec.io?ref=${params.id}`}
                target="_blank"
                rel="noreferrer"
                className="w-full sm:w-auto"
              >
                <button className="border border-primary text-primary transition-all duration-300 hover:bg-primary hover:text-darkish text-sm lg:text-base py-2.5 lg:py-4 px-12 rounded-full w-full sm:w-auto">
                  Refer Friends
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="web-padding mb-16 md:mb-24">
        <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
          {/* Have Questions */}
          <div className="referral-have-questions flex flex-col justify-between lg:col-span-3 p-8 md:p-10 lg:p-[60px] rounded-[30px]">
            <div className="">
              <p className="max-w-[285px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-[#141414]">
                Still have questions?
              </p>
              <p className="max-w-[338px] mb-12 text-[16px] md:text-[22px] leading-[130%] text-[#141414]">
                For more details, video tutorial and FAQs
              </p>
            </div>
            <div className="flex flex-col sm:flex-row flex-wrap  sm:items-center gap-2.5">
              <Link to="/" className="w-full sm:w-auto">
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full whitespace-nowrap w-full sm:w-auto">
                  Learn More
                </button>
              </Link>
              {/* <div className="">
                <button
                  onClick={() => setOpenLegendVideo(true)}
                  className="border border-black text-black transition-all duration-300 hover:bg-primary hover:border-primary hover:text-darkish text-sm lg:text-base py-2.5 lg:py-4 px-10 rounded-full whitespace-nowrap"
                >
                  Video Tutorial
                </button>
              </div> */}
            </div>
          </div>
          {/* Got Ideas */}
          <div className="relative col-span-1 lg:col-span-2 p-8 md:p-10 lg:p-[60px] rounded-[30px]">
            <img
              src={gotIdeasBg}
              alt=""
              className="absolute inset-0 w-full h-full object-cover rounded-[30px] hidden md:block"
            />
            <img
              src={gotIdeasMobileBg}
              alt=""
              className="absolute inset-0 w-full h-full object-cover rounded-[30px] md:hidden"
            />
            <div className="relative z-[1] flex flex-col justify-between h-full">
              <div className="">
                <p className="max-w-[285px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-white">
                  Got ideas?
                </p>
                <p className="max-w-[338px] mb-12 text-[16px] md:text-[22px] leading-[130%]  text-white">
                  Hit me up and join the tribe
                </p>
              </div>
              <div className="flex items-center gap-x-2.5">
                <a
                  href={twitterHandleLink}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="w-full sm:w-auto"
                >
                  <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full  whitespace-nowrap w-full sm:w-auto">
                    Got Ideas
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpendingGame;
