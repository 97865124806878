import React, { useState } from "react";
import * as Icons from "../../../assets/icons";

const FaqCard = ({ qst, ans, ans2, maxWidth, index }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <div className={maxWidth ? maxWidth : "max-w-[640px]"}>
          <div
            className={`flex items-center justify-between gap-6 border-b animation py-5 cursor-pointer ${
              open ? "border-[#C8A0FA]" : "border-zebec-black/[0.05]"
            }`}
            onClick={() => setOpen((preval) => !preval)}
          >
            <h2 className="text-zebec-primary font-normal text-[21px] lg:text-[29px] lg:leading-[120%]">
              {qst}
            </h2>
            <div className="h-[40px] flex-shrink-0 w-[40px] rounded-full transition-all duration-300 ease-in-out  grid place-content-center">
              {open ? (
                <Icons.GradientDropdownCircleIcon />
              ) : (
                <Icons.GradientRightCircleIcon />
              )}
            </div>
          </div>
          <div
            className={` text-zebec-primary overflow-hidden ${
              open ? "max-h-screen py-4" : "max-h-0"
            } transition-all duration-300 ease-in-out `}
          >
            {ans}
            {ans2 && (
              <>
                <br />
                <br />
                {ans2}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqCard;
