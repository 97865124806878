import React, { useState } from "react";
import HeroSection from "./integarte/HeroSection";
import ZebecInstantRealDeal from "./integarte/ZebecInstantRealDeal";
import LegendVideo from "./integarte/LegendVideo";
import ReferralEasySetup from "./integarte/ReferralEasy";
import SpendingGame from "./integarte/SpendingGame";
import { useParams } from "react-router-dom";
import { LEGEND_PARAMS } from "./legend-params";
import PageNotFound from "../404/PageNotFound";

const Legend = () => {
  const params = useParams();
  const [openLegendVideo, setOpenLegendVideo] = useState(false);

  if (
    params.id === LEGEND_PARAMS.COWBOY ||
    params.id === LEGEND_PARAMS.JOE ||
    params.id === LEGEND_PARAMS.SUGAR ||
    params.id === LEGEND_PARAMS.ICED ||
    params.id === LEGEND_PARAMS.GUMSHOE ||
    params.id === LEGEND_PARAMS.JAKEY ||
    params.id === LEGEND_PARAMS.CT ||
    params.id === LEGEND_PARAMS.HERNAN ||
    params.id === LEGEND_PARAMS.METASAL ||
    params.id === LEGEND_PARAMS.AGENCIADESOLUCIONES ||
    params.id === LEGEND_PARAMS.RSANJIB ||
    params.id === LEGEND_PARAMS.NFTBOI ||
    params.id === LEGEND_PARAMS.SOLANAANZ ||
    params.id === LEGEND_PARAMS.ICOPANTERA ||
    params.id === LEGEND_PARAMS.CHRIS ||
    params.id === LEGEND_PARAMS.UPROCK

    // params.id === LEGEND_PARAMS.BURRRD
  ) {
    return (
      <>
        <HeroSection />
        <ZebecInstantRealDeal />
        {params.id !== LEGEND_PARAMS.COWBOY && (
          <LegendVideo
            openLegendVideo={openLegendVideo}
            setOpenLegendVideo={setOpenLegendVideo}
          />
        )}
        <ReferralEasySetup />
        <SpendingGame set OpenLegendVideo={setOpenLegendVideo} />
      </>
    );
  } else {
    return <PageNotFound />;
  }
};

export default Legend;
