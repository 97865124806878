export const routes = [
  {
    component: "item",
    name: "Home",
    path: "/",
  },
  {
    component: "parent",
    name: "Products",
    path: "",
    children: [
      {
        name: "Zebec Cards",
        path: "/",
        isAnchor: false,
      },
      {
        name: "RWA Payments",
        path: "https://zebec.io/products/rwa-payments",
        isAnchor: false,
        extraLink: true,
      },
      {
        name: "DePIN",
        path: "https://zebec.io/products/depin",
        isAnchor: false,
        extraLink: true,
      },
      {
        name: "Zebec Vaults",
        path: "https://zebec.io/products/zebec-vaults",
        isAnchor: false,
        extraLink: true,
      },
    ],
  },
  {
    component: "item",
    name: "Company",
    path: "https://zebec.io/company",
    extraLink: true,
  },
  {
    component: "item",
    name: "Governance",
    path: "https://zebec.io/governance",
    extraLink: true,
  },
  {
    component: "item",
    name: "Airdrop",
    path: "https://zebec.io/airdrop",
    extraLink: true,
  },
];
