import React from "react";
import {
  ZBCPromoHeroBgImg,
  ZBCPromoHeroCardBgImg
} from "../../../assets/images";

const HeroSection = () => {
  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="relative">
          <div className="burrrd-hero-bg md:h-[calc(100vh-160px)] overflow-hidden rounded-[30px] md:flex md:bg-right bg-bottom bg-no-repeat">
            <img
              src={ZBCPromoHeroBgImg}
              className="w-full h-full object-center object-cover absolute top-0 right-0 rounded-[30px]"
              alt=""
            />
            <img
              src={ZBCPromoHeroCardBgImg}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:h-full"
              alt=""
            />
            <div className="relative z-[1] flex flex-col md:flex-row w-full h-full items-start pb-10">
              <div className="py-10 px-10 lg:px-20 flex flex-col w-full justify-center md:w-1/2">
                <p className="text-lg leading-6 text-primary max-w-md mt-4 md:mt-14 mb-5">
                  The Zebec Instant Card
                </p>
                <h1 className="text-4xl md:text-5xl lg:text-[48px] lg:leading-[48px] xl:text-[65px] xl:leading-[65px] text-dark-black">
                  Load, Spend, <br /> and Earn <br /> ZBC
                </h1>
              </div>
              <div className="py-10 px-10 lg:px-20 flex flex-col w-full self-end md:w-1/2 mt-auto md:mt-0">
                <h1 className="text-4xl md:text-5xl lg:text-[48px] lg:leading-[48px] xl:text-[65px] xl:leading-[65px] text-dark-black md:text-right">
                  – It's That <br /> Simple!
                </h1>
                <div className="flex items-center gap-x-2.5 mt-10 md:ml-auto">
                  <a
                    href="https://app.card.zebec.io/"
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                      Get Zebec Instant
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
