import React from "react";
import { BonkMapImg, BonkMasterVisaImg } from "../../../assets/images";

const BonkMap = () => {
  return (
    <>
      <div className="web-padding">
        <div className="grid sm:grid-cols-2 gap-6 xl:gap-10 mb-6">
          <div className="">
            <div className="sm:max-w-[750px] text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
              Perfect for Holidays <br /> Near or Far…
            </div>
          </div>
          <div className={`md:pl-[10%]`}>
            <div className="mb-7 max-w-[330px]">
              The Zebec Instant Card is available in 138 countries around the
              world, powered by global Visa and Mastercard networks
            </div>
            <img src={BonkMasterVisaImg} className="" alt="" />
          </div>
        </div>
        <img
          src={BonkMapImg}
          className="w-full h-full -translate-y-10"
          alt=""
        />
      </div>
    </>
  );
};

export default BonkMap;
