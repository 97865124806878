import React from "react";
import {
  RocketIcon,
  ShieldIcon,
  WorldIcon,
  ZeroFeeIcon,
} from "../../../assets/icons";

const cards = [
  {
    title: "Instant Power-Up",
    desc: "Activate your card in a snap directly from your wallet. Shop online, add to Apple Pay or Google Pay or order a physical card",
    icon: <RocketIcon className="text-primary" />,
    span: "sm:col-span-1",
  },
  {
    title: "Global Acceptance",
    desc: "Whether you’re chilling in Cali or trekking in Thailand, your Zebec Card’s got your back, powered by Visa and Mastercard and accepted worldwide",
    icon: <WorldIcon className="text-primary" />,
    span: "sm:col-span-1",
  },
  {
    title: "Total Control",
    desc: "It's your crypto, your rules. Enjoy a non-custodial card that keeps you in the driver's seat",
    icon: <ShieldIcon />,
    span: "sm:col-span-2",
  },
];

const BonkInstantCardRocks = () => {
  return (
    <>
      <div className="web-padding pt-16 pb-10 md:pt-[100px] md:pb-16">
        <div className="grid sm:grid-cols-2 gap-6 xl:gap-10 mb-6">
          <div className="">
            <div className="mb-5 sm:max-w-[750px] text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
              Why Zebec Instant Card Rocks!
            </div>
            <p className="mb-5 sm:max-w-[750px] text-zebec-black text-base xl:text-[22px] xl:leading-[130%]">
              You asked for a card that lets you spend BONK like cold, hard
              cash?  Meet The Zebec Instant Card:
            </p>
          </div>
          <div
            className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10`}
          >
            <div>
              <ZeroFeeIcon className="text-[#08E0A3]" />
            </div>
            <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
              Zero Fees, Zero Drama
            </h1>
            <div className="mt-5">
              We keep it real - zero sign-up, conversion, or hidden charges.
              Load BONK, spend in USD dollars on your everyday purchases
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 xl:gap-10">
          {cards.map((data, index) => {
            const { icon, title, desc, span } = data;
            return (
              <div
                key={index}
                className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10 ${span}`}
              >
                <div>{icon}</div>
                <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
                  {title}
                </h1>
                <div className="mt-5">{desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BonkInstantCardRocks;
