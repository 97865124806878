import React from "react";
import HeroSection from "./integrate/HeroSection";
import MagicCards from "./integrate/MagicCards";
import MagicRevolutionFloks from "./integrate/MagicRevolutionFloks";

const MagicInternetMoney = () => {
  return (
    <>
      <div>
        <HeroSection />
        <MagicCards />
        <MagicRevolutionFloks />
      </div>
    </>
  );
};

export default MagicInternetMoney;
