import { Fragment } from "react";
//size = "small" | "default" | large

export const Button = ({
  type = "button",
  title,
  startIcon,
  endIcon,
  children,
  className = "",
  disabled = false,
  size = "default",
  onClick
}) => {
  return (
    <Fragment>
      <button
        type={type}
        className={`relative flex items-center justify-center gap-x-2 rounded-full group/button w-max whitespace-nowrap transition ${size === "default"
          ? "pl-[5px] pr-[20px] pt-[5px] pb-[5px] text-[20px] md:text-[25px]"
          : size === "small"
            ? "py-1 px-2 h-[50px]"
            : "py-1 px-2 h-[50px]"
          } ${className}`}
        disabled={disabled}
        onClick={onClick}
      >
        {children ? (
          children
        ) : (
          <>
            {startIcon && <span>{startIcon}</span>}
            <span>{title}{" "}</span>
            {endIcon && <span>{endIcon}</span>}
          </>
        )}
      </button>
    </Fragment>
  );
};
