import { Fragment, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button } from "../shared/Button";
import * as Images from "../../assets/images";
import * as Icons from "../../assets/icons";
import { routes } from "./routes";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../hooks/useClickOutside";

const Languages = [
  {
    language: "US",
    code: "en",
  },
  {
    language: "TR",
    code: "tr",
  },
  {
    language: "ES",
    code: "es",
  },
];

const MobileNavDropdown = ({ route, openParent, setIsMenuToggled }) => {
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);

  const returnMobileChildrenComponent = (route) => {
    const childrenObject = route;
    if (childrenObject?.children.length > 0) {
      return childrenObject.children.map((child, index) => {
        if (child.isAnchor) {
          return (
            <div key={index} className="">
              <a
                href={child.path}
                target={`${child.extraLink ? "_blank" : ""}`}
                rel="noreferrer"
                onClick={() => {
                  setIsMenuToggled(false);
                  setToggleMenu(false);
                }}
                className="font-ppmori text-zebec-primary mt-3 mb-2"
              >
                {child.name}
              </a>
            </div>
          );
        } else {
          return (
            <Fragment key={index}>
              {child.name === "Zebec Card" ? (
                <NavLink
                  to={{
                    pathname: child.path,
                    state: {
                      name: childrenObject.name,
                    },
                  }}
                  onClick={() => {
                    setIsMenuToggled(false);
                    setToggleMenu(false);
                  }}
                  key={index}
                  className={({ isActive }) =>
                    `${
                      isActive ? "gradient-text" : ""
                    }  font-ppmori text-zebec-primary mb-2`
                  }
                >
                  <div className="flex flex-col">{child.name}</div>
                </NavLink>
              ) : (
                <a
                  href={child.path}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    setIsMenuToggled(false);
                    setToggleMenu(false);
                  }}
                  key={index}
                  className={`font-ppmori text-zebec-primary mb-2`}
                >
                  <div className="flex flex-col">{child.name}</div>
                </a>
              )}
            </Fragment>
          );
        }
      });
    } else {
      return null;
    }
  };

  return (
    <div className="">
      <div
        className={`flex items-center gap-x-2  hover:text-zebec-primary font-ppmori cursor-pointer ${
          location?.state?.name === route.name ||
          (openParent.open && openParent.name === route.name)
            ? "gradient-text"
            : "text-zebec-primary"
        }`}
        onClick={() => setToggleMenu(!toggleMenu)}
      >
        {route.name}
        <Icons.CheveronDownIcon
          className={`w-3 h-3 transition-all ${
            openParent.open && openParent.name === route.name && "rotate-180"
          } ${toggleMenu && "rotate-180"}`}
        />
      </div>

      <div
        className={`ml-6 transition-all duration-300 overflow-hidden ${
          toggleMenu ? "max-h-[140px]" : "max-h-0"
        }`}
      >
        {returnMobileChildrenComponent(route)}
      </div>
    </div>
  );
};

const Navbar = () => {
  const navRef = useRef();
  const languageRef = useRef();

  const location = useLocation();
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [language, setLanguage] = useState("US");
  const { i18n } = useTranslation();

  const handleLanguageChange = (data) => {
    setLanguage(data.language);
    i18n.changeLanguage(data.code);
    setOpenLanguageModal(false);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useClickOutside(languageRef, {
    onClickOutside: () => {
      setOpenLanguageModal(false);
    },
  });

  useEffect(() => {
    if (i18n.resolvedLanguage) {
      const lang = Languages.filter(
        (data) => data.code === i18n.resolvedLanguage
      );
      setLanguage(lang[0].language ?? "en");
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuToggled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuToggled]);

  //Products
  const [openParent, setOpenParent] = useState({
    open: false,
    name: "",
  });

  useEffect(() => {
    setOpenParent({
      open: false,
      name: openParent.name,
    });
    // eslint-disable-next-line
  }, [location.pathname]);

  const returnChildrenComponent = () => {
    const childrenObject = routes.find(
      (route) => route.name === openParent.name
    );
    if (childrenObject?.children.length > 0) {
      return childrenObject.children.map((child) => {
        if (child.isAnchor) {
          return (
            <a
              key={child.name}
              href={child.path}
              target={`${child.extraLink ? "_blank" : ""}`}
              rel="noreferrer"
              onClick={() =>
                setOpenParent({
                  name: "",
                  open: false,
                })
              }
              className=" hover:gradient-text font-ppmori text-zebec-primary mt-3 mb-1"
            >
              {child.name}
            </a>
          );
        } else {
          return (
            <>
              {child.name === "Zebec Cards" ? (
                <NavLink
                  to={{
                    pathname: child.path,
                    state: {
                      name: childrenObject.name,
                    },
                  }}
                  key={child.name}
                  className={({ isActive }) =>
                    `${
                      isActive ? "gradient-text" : ""
                    }  hover:gradient-text font-ppmori text-zebec-primary mb-1`
                  }
                >
                  <div className="flex flex-col">
                    {child.name}
                    <div className="gradient-text">{child.icon}</div>
                  </div>
                </NavLink>
              ) : (
                <a
                  href={child.path}
                  rel="noreferrer"
                  target="_blank"
                  className=" hover:text-zebec-primary font-ppmori text-zebec-primary mb-1"
                >
                  <div className="flex flex-col">
                    {child.name}
                    <div className="gradient-text">{child.icon}</div>
                  </div>
                </a>
              )}
            </>
          );
        }
      });
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <nav ref={navRef} className="bg-white">
        <div className={`relative z-30 ${openParent.open && "border-b"}`}>
          <div className="web-container flex items-center justify-between py-6 gap-6">
            <NavLink to="/">
              <img
                src={Images.ZebecNetworkLogo}
                className="max-h-[38px] w-auto"
                alt="Zebec logo"
              />
            </NavLink>
            <div className="hidden lg:flex items-center gap-x-6 lg:gap-x-[30px]">
              <div className="flex items-center gap-x-7 lg:gap-x-10">
                {routes.map((route) => {
                  switch (route.component) {
                    case "item":
                      return (
                        <NavLink
                          key={route.name}
                          to={route.path}
                          target={route.extraLink ? "_blank" : "_self"}
                          rel={route.extraLink ? "noreferrer noopener" : ""}
                          className={({ isActive }) =>
                            `${
                              isActive ? "gradient-text" : ""
                            }  hover:gradient-text font-ppmori text-zebec-primary`
                          }
                        >
                          {route.name}
                        </NavLink>
                      );
                    case "parent":
                      return (
                        <div
                          key={route.name}
                          className={`flex items-center gap-x-1 hover:gradient-text font-ppmori cursor-pointer ${
                            location?.state?.name === route.name ||
                            (openParent.open && openParent.name === route.name)
                              ? "gardient-text"
                              : "text-zebec-primary"
                          }`}
                          onClick={() =>
                            openParent.open && openParent.name === route.name
                              ? setOpenParent({
                                  name: "",
                                  open: false,
                                })
                              : setOpenParent({
                                  name: route.name,
                                  open: true,
                                })
                          }
                        >
                          {route.name}
                          <Icons.GradientCheveronRightIcon
                            className={`w-4 h-4 transition-all ${
                              openParent.open &&
                              openParent.name === route.name &&
                              "rotate-90"
                            }`}
                          />
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
              <a
                href="https://app.card.zebec.io/"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <Button
                  title="Connect Wallet"
                  className="gradient-button-border text-zebec-primary text-sm lg:text-base rounded-full bg-white"
                  startIcon={
                    <div
                      className={`w-10 h-10 rounded-full bg-icon-gradient grid place-content-center`}
                    >
                      <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                    </div>
                  }
                />
              </a>
              <div className="relative" ref={languageRef}>
                <div
                  className="w-10 h-10 rounded-[8px] bg-gray-light cursor-pointer grid place-content-center"
                  onClick={() => setOpenLanguageModal(true)}
                >
                  <img
                    alt=""
                    className="w-6 h-6 rounded object-fit"
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${language}.svg`}
                  />
                </div>
                <Transition
                  as={Fragment}
                  show={openLanguageModal}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-75"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-200"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-75"
                >
                  <div className="absolute top-11 rounded right-0 bg-gray-light w-14 px-2 py-1 space-y-1">
                    {Languages.map((data) => {
                      return (
                        <div
                          className="cursor-pointer"
                          key={data.code}
                          onClick={() => handleLanguageChange(data)}
                        >
                          <img
                            alt=""
                            className="w-6 h-6 rounded object-fit mx-auto"
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.language}.svg`}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Transition>
              </div>
            </div>
            <button
              className="lg:hidden"
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              {isMenuToggled ? (
                <Icons.CrossIcon className="w-8 h-8 text-zebec-black" />
              ) : (
                <Icons.MenuIcon />
              )}
            </button>
          </div>
        </div>
        <div className="hidden lg:block">
          <div
            className={`lg:transition-all lg:duration-300 lg:ease-in-out overflow-hidden h-0 opacity-0 ${
              openParent.open ? "h-[80px] opacity-100" : ""
            }`}
          >
            <div className="web-container lg:flex justify-end gap-x-10 z-30 py-5">
              {returnChildrenComponent()}
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Nav */}
      <div
        className={`bg-white`}
        style={{
          bottom: isMenuToggled
            ? "0px"
            : `calc(100vh - ${
                navRef.current?.offsetHeight - scrollPosition
              }px)`,
          top: `${navRef.current?.offsetHeight + scrollPosition}px`,
        }}
      >
        <div
          className={`web-container bg-white flex flex-col items-start gap-y-5 transition-all duration-500 overflow-x-hidden ${
            isMenuToggled ? "h-full py-10" : "h-0"
          }`}
        >
          {routes.map((route) => {
            switch (route.component) {
              case "item":
                return (
                  <NavLink
                    key={route.name}
                    to={route.path}
                    onClick={() => setIsMenuToggled(!isMenuToggled)}
                    className={({ isActive }) =>
                      `${
                        isActive ? "gradient-text" : ""
                      } hover:gardient-text font-ppmori text-zebec-primary`
                    }
                  >
                    {route.name}
                  </NavLink>
                );
              case "parent":
                return (
                  <MobileNavDropdown
                    key={route.name}
                    route={route}
                    openParent={openParent}
                    setIsMenuToggled={setIsMenuToggled}
                  />
                );
              default:
                return null;
            }
          })}
          <a
            href="https://app.card.zebec.io/"
            target="_blank"
            rel="noreferrer"
            className=""
          >
            <Button
              title="Connect Wallet"
              className="gradient-button-border text-zebec-primary text-sm lg:text-base rounded-full bg-white"
              startIcon={
                <div
                  className={`w-10 h-10 rounded-full bg-icon-gradient grid place-content-center`}
                >
                  <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                </div>
              }
            />
          </a>
          <div className="relative" ref={languageRef}>
            <div
              className="w-10 h-10 rounded-[8px] bg-gray-light cursor-pointer grid place-content-center"
              onClick={() => setOpenLanguageModal(true)}
            >
              <img
                alt=""
                className="w-6 h-6 rounded object-fit"
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${language}.svg`}
              />
            </div>
            <Transition
              as={Fragment}
              show={openLanguageModal}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-75"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-200"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-75"
            >
              <div className="absolute top-11 rounded right-0 bg-gray-light w-14 px-2 py-1 space-y-1">
                {Languages.map((data) => {
                  return (
                    <div
                      className="cursor-pointer"
                      key={data.code}
                      onClick={() => {
                        handleLanguageChange(data);
                        setIsMenuToggled(!isMenuToggled);
                      }}
                    >
                      <img
                        alt=""
                        className="w-6 h-6 rounded object-fit mx-auto"
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.language}.svg`}
                      />
                    </div>
                  );
                })}
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
