import React from "react";
import { BonkHeroImg, BonkMobileHeroSectionImg } from "../../../assets/images";

const HeroSection = () => {
  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="relative">
          <div className="bonk-hero-bg md:h-[calc(100vh-160px)] overflow-hidden rounded-[30px] md:flex md:bg-right bg-bottom bg-no-repeat">
            <img
              src={BonkHeroImg}
              className="w-full h-full object-right object-cover absolute top-0 right-0 hidden lg:block rounded-[30px]"
              alt=""
            />
            <img
              src={BonkMobileHeroSectionImg}
              className="w-full object-cover object-bottom absolute top-0 right-0 lg:hidden rounded-[30px] h-[650px] sm:h-full lg:h-[650px]"
              alt=""
            />
            <div className="relative z-[1] flex flex-col md:flex-row w-full md:items-start lg:items-center  pb-10">
              <div className="py-10 px-10 lg:px-20 flex flex-col justify-center w-full lg:w-1/2">
                <h1 className="text-4xl md:text-5xl lg:text-[65px] lg:leading-[65px] text-white">
                  Just in Time for <br /> $BONKmas!
                </h1>
                <p className="text-base leading-6 text-white max-w-md mt-5">
                  Spend $BONK with Zebec Instant Card Anytime, Anywhere,
                  Instantly
                </p>
                <div className="flex items-center gap-x-2.5 mt-10">
                  <a
                    href="https://app.card.zebec.io/"
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                      Get Instant Now
                    </button>
                  </a>
                  {/* <a
                    href="https://app.card.zebec.io/"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <button className="border border-primary text-primary transition-all duration-300 hover:bg-primary hover:text-darkish text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full ">
                      Refer Friends
                    </button>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
