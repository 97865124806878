import React from "react";
import {
  AmbassadorImg,
  ReferralBackgroundImg,
  ReferralMobileHeroBgImg
} from "../../../assets/images";

const HeroSection = () => {
  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="relative">
          <div className="referrall-hero-bg md:h-[calc(100vh-160px)] overflow-hidden rounded-[30px] md:flex md:bg-right bg-bottom bg-no-repeat">
            <img
              src={ReferralBackgroundImg}
              className="w-full h-full object-right object-cover absolute top-0 right-0 hidden md:block rounded-[30px]"
              alt=""
            />
            <img
              src={ReferralMobileHeroBgImg}
              className="w-full object-cover object-bottom absolute top-0 right-0 md:hidden rounded-[30px] h-[650px]"
              alt=""
            />
            <div className="relative z-[1] flex flex-col md:flex-row w-full items-center pb-10">
              <div className="py-10 px-10 lg:px-20 flex flex-col justify-center w-full md:w-1/2">
                <h1 className="text-4xl md:text-5xl lg:text-[65px] lg:leading-[65px] text-white">
                  The Zebec <br /> Instant Card
                </h1>
                <p className="text-base leading-6 text-primary max-w-md mt-5">
                  Ready for this wildfire in the world of crypto? Dive in!
                </p>
                <div className="flex flex-col sm:flex-row flex-wrap gap-y-6 sm:items-center gap-x-2.5 mt-10">
                  <a
                    href="https://app.card.zebec.io?ref=legend"
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full whitespace-nowrap w-full sm:w-auto">
                      Get Instant Now
                    </button>
                  </a>
                  <a
                    href="https://app.card.zebec.io?ref=legend"
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <button className="border border-primary text-primary transition-all duration-300 hover:bg-primary hover:text-darkish text-sm lg:text-base py-2.5 lg:py-4 px-10 rounded-full  whitespace-nowrap w-full sm:w-auto">
                      Refer Friends
                    </button>
                  </a>
                </div>
              </div>
              {/* Ambassador Image */}
              <div className="w-full md:w-1/2 px-10 md:px-24 lg:px-32 xl:px-[150px]">
                <img
                  src={AmbassadorImg}
                  alt=""
                  className="w-[200px] h-[200px] md:w-[250px] md:h-[250px] mx-auto sm:mx-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
