import React from "react";
import { Link } from "react-router-dom";

const MagicRevolutionFloks = () => {
  return (
    <>
      <div className="web-padding mt-8 mb-16 md:mb-24">
        <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
          {/* Have Questions */}
          <div className="magic-revolution-floks flex flex-col justify-between lg:col-span-3 p-8 md:p-10 lg:p-[60px] rounded-[30px]">
            <div className="">
              <p className="max-w-[385px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-white">
                Portal to Magic Freedom:
              </p>
              <p className="max-w-[338px] mb-12 text-[16px] md:text-[22px] leading-[130%]  text-white">
                Join the league of enlightened spenders. Apply for your Zebec
                Instant Card today and turn every payment into an opportunity to
                witness the magic of MIM.
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <a
                href="https://app.card.zebec.io/"
                target="_blank"
                rel="noreferrer"
                className="w-full sm:w-auto"
              >
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                  Get Instant Now
                </button>
              </a>
            </div>
          </div>
          {/* Got Ideas */}
          <div className="magic-curious-for-more col-span-1 flex flex-col justify-between lg:col-span-2 p-8 md:p-10 lg:p-[60px] rounded-[30px]">
            <div className="">
              <p className="max-w-[385px] mb-5 text-[25px] md:text-[40px] leading-[130%] text-white">
                Crave More Magic?
              </p>
              <p className="max-w-[338px] mb-12 text-[16px] md:text-[22px] leading-[130%]  text-white">
                Dive deeper into the enchanted world of the Zebec Instant Card.
                Explore the enchanted archives and magic manuals here
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <Link to="/" className="w-full sm:w-auto">
                <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full w-full sm:w-auto">
                  Find Answers
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MagicRevolutionFloks;
