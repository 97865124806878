import React from "react";
import {
  RocketIcon,
  SecurityIcon,
  WorldIcon,
  ZeroFeeIcon,
} from "../../../assets/icons";

const cards = [
  {
    title: "Lightning Speed: The Quickening Charms!",
    desc: "Prepare to be amazed as your MIMs fund the Zebec Instant in the blink of an eye. It’s not just fast; it’s instant magic.",
    icon: <RocketIcon className="text-primary" />,
    span: "sm:col-span-2",
  },
  {
    title: "Global Enchantment: Accepted Across the Lands!",
    desc: "The magic of the Zebec Instant Card knows no bounds. Accepted in marketplaces far and wide, it empowers you to spend your MIM wherever your quest takes you. From the bustling bazaars on land to mystical markets of the internet, your card is the key to a world of possibilities.",
    icon: <WorldIcon className="text-primary" />,
    span: "sm:col-span-3",
  },
  {
    title: "Sovereignty of Spending: Your Financial Realm Commanded!",
    desc: "The power of the non-custodial Zebec Instant Card lies not just in its speed and cost-effectiveness but in the control it gives you. Integrated with MIM, keeps you in charge of your enchanted assets.",
    icon: <SecurityIcon />,
    span: "sm:col-span-3",
  },
];

const MagicCards = () => {
  return (
    <>
      <div className="web-padding pt-16 pb-10 md:pt-[100px] md:pb-16">
        <div className="grid sm:grid-cols-2 gap-6 xl:gap-10 mb-6">
          <div className="">
            <div className="mb-5 sm:max-w-[750px] text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
              The Alchemy of Instant Spending: How Zebec Instant Weaves Magic!
            </div>
            <p className="mb-5 sm:max-w-[750px] text-zebec-black text-base xl:text-[22px] xl:leading-[130%]">
              Introducing the Zebec Instant Card integrated with Magic Internet
              Money - where financial transactions happen with the wave of a
              wand. Imagine a world where every payment is not just a
              transaction but a magical moment of simplicity and speed.
            </p>
          </div>
          <div
            className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10`}
          >
            <div>
              <ZeroFeeIcon className="text-[#08E0A3]" />
            </div>
            <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
              Spell of Zero Fees: The Liberating Magic!
            </h1>
            <div className="mt-5">
              Wave goodbye to the dreaded fees that haunt your financial
              adventures. With the Zebec Instant Card powered by MIM, experience
              the true freedom of zero fees. Let your funds flourish under the
              protection of our magical ecosystem.
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-8 gap-6 xl:gap-10">
          {cards.map((data, index) => {
            const { icon, title, desc, span } = data;
            return (
              <div
                key={index}
                className={`bg-zebec-black/[0.02] text-zebec-black rounded-[20px] p-6 xl:p-10 ${span}`}
              >
                <div>{icon}</div>
                <h1 className="text-[25px] lg:text-[29px] leading-[120%] mt-8">
                  {title}
                </h1>
                <div className="mt-5">{desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MagicCards;
