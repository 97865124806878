import { Fragment, useRef } from "react";
import { useState } from "react";
import { CloseVideoIcon, PlayButtonIcon } from "../../assets/icons";
import { VideoThumbnail } from "../../assets/images";

const HowToVideoLoop = ({ setOpenVideoLoop, video, thumbnail }) => {
  const [playVideo, setPlaVideo] = useState(false);
  const vidRef = useRef();

  const hanlePlayPause = () => {
    if (vidRef.current) {
      setPlaVideo(true);
      vidRef.current.play();
    }
  };
  return (
    <div className="fixed flex justify-center items-center lg:flex-none place-content-center w-screen h-screen z-[100] top-0 right-0">
      {/* {playVideo ? null : (
        <div className="w-full h-full bg-black fixed top-0 z-[10] right-0 bg-opacity-30 backdrop-filter backdrop-blur-sm"></div>
      )} */}
      <div className="w-full h-full bg-black fixed top-0 z-[10] right-0 bg-opacity-30 backdrop-filter backdrop-blur-sm"></div>
      <button
        className="fixed top-[3%] right-[3%] z-[1000]"
        onClick={() => setOpenVideoLoop(false)}
      >
        <CloseVideoIcon className="w-7 h-7 " />
      </button>
      <Fragment>
        <video
          // autoPlay
          // muted
          controls={true}
          loop
          playsInline
          className={`lg:w-full lg:object-cover z-[100] lg:h-full ${
            playVideo ? "" : "hidden"
          }`}
          ref={vidRef}
          id="zebec_banner_video"
        >
          <source src={video} type="video/mp4" />
        </video>
        <div
          className={`w-full h-full relative flex lg:flex-none justify-center items-center ${
            playVideo ? "hidden" : ""
          }`}
        >
          <button
            className="absolute top-1/2 z-[100] left-1/2"
            onClick={hanlePlayPause}
          >
            <PlayButtonIcon />
          </button>
          <img
            src={thumbnail ? thumbnail : VideoThumbnail}
            className="lg:w-full lg:h-full"
            alt=""
          />
        </div>
      </Fragment>
    </div>
  );
};

export default HowToVideoLoop;
