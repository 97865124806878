import React from "react";
import {
  ReferralBackgroundImg,
  ReferralMobileHeroBgImg,
} from "../../../assets/images";
import { useParams } from "react-router-dom";

import {
  CowboyHeroBg,
  CowboyMobileHeroBg,
  IcedHeroBg,
  IcedMobileHeroBg,
  GameshoeHeroBg,
  GameshoeMobileHeroBg,
  CowboyAvatar,
  IcedAvatar,
  GameshoeAvatar,
  JoeAvatar,
  SugarAvatar,
  JoeHeroBg,
  SugarHeroBg,
  JoeMobileHeroBg,
  SugarMobileHeroBg,
  BurrrdAvatar,
  BurrrdHeroBg,
  BurrrdMobileHeroBg,
  GenericAvatar,
  JakeyHeroBg,
  JakeyMobileHeroBg,
  JakeyAvatar,
  CTAvatar,
  ChrisAvatar,
  ChrisHeroBg,
  ChrisMobileHeroBg,
} from "../../../assets/images/legend";
import { LEGEND_PARAMS } from "../legend-params";

const HeroSection = () => {
  const params = useParams();

  const heroAvatar =
    params.id === LEGEND_PARAMS.COWBOY
      ? CowboyAvatar
      : params.id === LEGEND_PARAMS.ICED
      ? IcedAvatar
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? GameshoeAvatar
      : params.id === LEGEND_PARAMS.JOE
      ? JoeAvatar
      : params.id === LEGEND_PARAMS.SUGAR
      ? SugarAvatar
      : params.id === LEGEND_PARAMS.BURRRD
      ? BurrrdAvatar
      : params.id === LEGEND_PARAMS.JAKEY
      ? JakeyAvatar
      : params.id === LEGEND_PARAMS.CT
      ? CTAvatar
      : params.id === LEGEND_PARAMS.CHRIS
      ? ChrisAvatar
      : GenericAvatar;

  const heroBackgroundImage =
    params.id === LEGEND_PARAMS.COWBOY
      ? CowboyHeroBg
      : params.id === LEGEND_PARAMS.ICED
      ? IcedHeroBg
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? GameshoeHeroBg
      : params.id === LEGEND_PARAMS.JOE
      ? JoeHeroBg
      : params.id === LEGEND_PARAMS.SUGAR
      ? SugarHeroBg
      : params.id === LEGEND_PARAMS.BURRRD
      ? BurrrdHeroBg
      : params.id === LEGEND_PARAMS.JAKEY || params.id === LEGEND_PARAMS.CT
      ? JakeyHeroBg
      : params.id === LEGEND_PARAMS.CHRIS
      ? ChrisHeroBg
      : ReferralBackgroundImg;

  const heroMobileBackgroundImage =
    params.id === LEGEND_PARAMS.COWBOY
      ? CowboyMobileHeroBg
      : params.id === LEGEND_PARAMS.ICED
      ? IcedMobileHeroBg
      : params.id === LEGEND_PARAMS.GUMSHOE
      ? GameshoeMobileHeroBg
      : params.id === LEGEND_PARAMS.JOE
      ? JoeMobileHeroBg
      : params.id === LEGEND_PARAMS.SUGAR
      ? SugarMobileHeroBg
      : params.id === LEGEND_PARAMS.BURRRD
      ? BurrrdMobileHeroBg
      : params.id === LEGEND_PARAMS.JAKEY || params.id === LEGEND_PARAMS.CT
      ? JakeyMobileHeroBg
      : params.id === LEGEND_PARAMS.CHRIS
      ? ChrisMobileHeroBg
      : ReferralMobileHeroBgImg;

  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="relative">
          <div className="referrall-hero-bg md:h-[calc(100vh-160px)] overflow-hidden rounded-[30px] md:flex md:bg-right bg-bottom bg-no-repeat">
            <img
              src={heroBackgroundImage}
              className="w-full h-full object-right object-cover absolute top-0 right-0 hidden md:block rounded-[30px]"
              alt=""
            />
            <img
              src={heroMobileBackgroundImage}
              className="w-full object-cover object-bottom absolute top-0 right-0 md:hidden rounded-[30px] h-[650px]"
              alt=""
            />
            <div className="relative z-[1] flex flex-col md:flex-row w-full items-center pb-10">
              <div className="py-10 px-10 lg:px-20 flex flex-col justify-center w-full md:w-1/2">
                <h1 className="text-4xl md:text-5xl lg:text-[65px] lg:leading-[65px] text-white">
                  The Zebec <br /> Instant Card
                </h1>
                <p className="text-base leading-6 text-primary max-w-md mt-5">
                  Ready for this wildfire in the world of crypto? Dive in!
                </p>
                <div className="flex flex-col sm:flex-row flex-wrap gap-y-6 sm:items-center gap-x-2.5 mt-10">
                  <a
                    href={`https://app.card.zebec.io?ref=${params.id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <button className="bg-primary text-sm lg:text-base py-2.5 lg:py-4 px-8 rounded-full whitespace-nowrap w-full sm:w-auto">
                      Get Instant Now
                    </button>
                  </a>
                  <a
                    href={`https://app.card.zebec.io?ref=${params.id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <button className="border border-primary text-primary transition-all duration-300 hover:bg-primary hover:text-darkish text-sm lg:text-base py-2.5 lg:py-4 px-10 rounded-full  whitespace-nowrap w-full sm:w-auto">
                      Refer Friends
                    </button>
                  </a>
                </div>
              </div>
              {/* Ambassador Image */}
              <div className="w-full md:w-1/2 px-10 md:px-12 lg:px-32 xl:px-[150px]">
                <img
                  src={heroAvatar}
                  alt=""
                  className="w-[200px] h-[200px] md:w-[250px] md:h-[250px] mx-auto sm:mx-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
