import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Icons from "../../../assets/icons";

const FaqCard = ({ qst, ans, ans2, maxWidth, index }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      {index === 1 || index === 2 || index === 5 ? (
        <div>
          <div className={maxWidth ? maxWidth : "max-w-[640px]"}>
            <div
              className={`flex items-center justify-between gap-6 border-b animation py-5 cursor-pointer ${
                open ? "border-[#C8A0FA]" : "border-zebec-black/[0.05]"
              }`}
              onClick={() => setOpen((preval) => !preval)}
            >
              <h2 className="text-zebec-primary font-normal text-[21px] lg:text-[29px] lg:leading-[120%]">
                {t("faq:faqs.In_which_countries")}
              </h2>
              <div
                className={`h-[40px] flex-shrink-0 w-[40px] rounded-full transition-all duration-300 ease-in-out  grid place-content-center
                  `}
              >
                {open ? (
                  <Icons.GradientDropdownCircleIcon />
                ) : (
                  <Icons.GradientRightCircleIcon />
                )}
              </div>
            </div>
            <div
              className={` text-zebec-primary overflow-hidden ${
                open ? "max-h-screen py-4" : "max-h-0"
              } transition-all duration-300 ease-in-out `}
            >
              <p>
                {t("faq:faqs.Our_card")}{" "}
                <strong
                  className="gradient-text font-normal hover:underline"
                  onClick={() => {
                    if (window !== undefined) {
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  <Link to="/visa-master">{t("faq:faqs.here")}</Link>
                </strong>{" "}
                {t("faq:faqs.after_hear")}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={maxWidth ? maxWidth : "max-w-[640px]"}>
            <div
              className={`flex items-center justify-between gap-6 border-b animation py-5 cursor-pointer ${
                open ? "border-[#C8A0FA]" : "border-zebec-black/[0.05]"
              }`}
              onClick={() => setOpen((preval) => !preval)}
            >
              <h2 className="text-zebec-primary font-normal text-[21px] lg:text-[29px] lg:leading-[120%]">
                {t(qst)}
              </h2>
              <div className="h-[40px] flex-shrink-0 w-[40px] rounded-full transition-all duration-300 ease-in-out  grid place-content-center">
                {open ? (
                  <Icons.GradientDropdownCircleIcon />
                ) : (
                  <Icons.GradientRightCircleIcon />
                )}
              </div>
            </div>
            <div
              className={` text-zebec-primary overflow-hidden ${
                open ? "max-h-screen py-4" : "max-h-0"
              } transition-all duration-300 ease-in-out `}
            >
              {t(ans)}
              {ans2 && (
                <>
                  <br />
                  <br />
                  {t(ans2)}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FaqCard;
