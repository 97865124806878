import React from "react";
import { BonkMasterVisaImg } from "../../../assets/images";
import {
  SillyDragonMapAvatarImg,
  SillyDragonMapImg
} from "../../../assets/images/partners";
const SillyDragonMap = () => {
  return (
    <>
      <div className="web-padding">
        <div className="grid sm:grid-cols-2 gap-6 xl:gap-10 mb-6">
          <div className="">
            <div className="sm:max-w-[750px] text-zebec-black text-2xl xl:text-[40px] xl:leading-[130%]">
              Perfect for Holidays <br /> Near or Far…
            </div>
          </div>
          <div className={`md:pl-[10%]`}>
            <div className="mb-7 max-w-[330px]">
              The Zebec Instant Card is available in 138 countries around the
              world, powered by global Visa and Mastercard networks
            </div>
            <img src={BonkMasterVisaImg} className="" alt="" />
          </div>
        </div>

        <div className="relative mt-20">
          <img
            src={SillyDragonMapImg}
            className="w-full h-full -translate-y-10"
            alt=""
          />
          <img
            src={SillyDragonMapAvatarImg}
            className="absolute bottom-0 left-0 w-[80px] h-[80px] sm:w-[120px] sm:h-[120px] lg:w-[200px] lg:h-[200px] -translate-y-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default SillyDragonMap;
