import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// languages
import commonEN from "./en/common.json";
import homepageEN from "./en/homepage.json";
import industryFirstEN from "./en/industryFirst.json";
import zebecEasySetupEN from "./en/zebecEasySetup.json";
import giftEN from "./en/gift.json";
import faqEN from "./en/faq.json";
import zebecBlackEN from "./en/zebecBlack.json";

import commonTR from "./turkish/common.json";
import homepageTR from "./turkish/homepage.json";
import industryFirstTR from "./turkish/industryFirst.json";
import zebecEasySetupTR from "./turkish/zebecEasySetup.json";
import giftTR from "./turkish/gift.json";
import faqTR from "./turkish/faq.json";
import zebecBlackTR from "./turkish/zebecBlack.json";

import commonES from "./spanish/common.json";
import homepageES from "./spanish/homepage.json";
import industryFirstES from "./spanish/industryFirst.json";
import zebecEasySetupES from "./spanish/zebecEasySetup.json";
import giftES from "./spanish/gift.json";
import faqES from "./spanish/faq.json";
import zebecBlackES from "./spanish/zebecBlack.json";

// the translations
const resources = {
  en: {
    common: commonEN,
    homepage: homepageEN,
    industryFirst: industryFirstEN,
    gift: giftEN,
    zebecEasySetup: zebecEasySetupEN,
    faq: faqEN,
    zebecBlack: zebecBlackEN,
  },
  tr: {
    common: commonTR,
    homepage: homepageTR,
    industryFirst: industryFirstTR,
    gift: giftTR,
    zebecEasySetup: zebecEasySetupTR,
    faq: faqTR,
    zebecBlack: zebecBlackTR,
  },
  es: {
    common: commonES,
    homepage: homepageES,
    industryFirst: industryFirstES,
    gift: giftES,
    zebecEasySetup: zebecEasySetupES,
    faq: faqES,
    zebecBlack: zebecBlackES,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: resources,
  });

export default i18n;
