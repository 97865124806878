import React from "react";

import { PartnerPageBg, PartnerSundogImg } from "../../assets/images/partner";
import { Button } from "../shared/Button";
import * as Icons from "../../assets/icons";

const SundogHeroSection = () => {
  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="relative">
          <div className="referrall-hero-bg min-h-[500px] md:h-[calc(100vh-160px)] overflow-hidden rounded-[30px] md:flex md:bg-right bg-bottom bg-no-repeat">
            <img
              src={PartnerPageBg}
              className="w-full h-full object-right object-cover absolute top-0 right-0 rounded-[30px]"
              alt=""
            />
            <div className="relative z-[1] flex flex-col md:flex-row w-full items-center pb-10">
              <div className="py-10 pl-10 pr-6 lg:pl-20 flex flex-col justify-center w-full md:w-1/2">
                <h1 className="text-4xl md:text-5xl lg:text-[65px] lg:leading-[65px] text-primary ">
                  Fund with Crypto.
                  <br />
                  Pay in Fiat.
                </h1>
                <p className="text-base leading-6 text-white max-w-md mt-5">
                  Reloadable + Limitless + Global
                </p>
                <div className="flex flex-col sm:flex-row flex-wrap gap-y-6 sm:items-center gap-x-5 mt-10">
                  <a
                    href={`https://zebec-sundog.mobilum.com/`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <Button
                      title={"Get Card"}
                      className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary m-auto mt-4"
                      startIcon={
                        <div
                          className={
                            "w-10 h-10 rounded-full bg-primary grid place-content-center"
                          }
                        >
                          <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                        </div>
                      }
                    />
                  </a>
                  <a
                    href={`https://app.card.zebec.io/sundog`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-auto"
                  >
                    <Button
                      title={"Load up Sundog"}
                      className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary m-auto mt-4 !px-4 !py-3 !h-[52px]"
                    />
                  </a>
                </div>
              </div>
              {/* Brand Image */}
              <div className="w-full md:w-1/2 px-10">
                <img
                  src={PartnerSundogImg}
                  alt=""
                  className="max-w-[550px] w-full h-auto mx-auto sm:mx-0 mt-6 md:mt-0 rotate-[12deg]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SundogHeroSection;
