import React from "react";
import HeroSection from "./integrate/HeroSection";
import SillyDragonInstantCardRocks from "./integrate/SillyInstantCardRocks";
import SillyDragonMap from "./integrate/SillyMap";
import SillyDragonRevolutionFloks from "./integrate/SillyRevolutionFloks";

const SillyDragon = () => {
  return (
    <>
      <div>
        <HeroSection />
        <SillyDragonInstantCardRocks />
        <SillyDragonMap />
        <SillyDragonRevolutionFloks />
      </div>
    </>
  );
};

export default SillyDragon;
