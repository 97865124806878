import React from "react";
import {
  ReferralMobileSpendingGameBgImg,
  ReferralSpendingGameImg
} from "../../assets/images";

const PageNotFound = () => {
  return (
    <>
      <div className="my-10 md:my-24 web-padding ">
        <div className="relative w-full rounded-[30px]">
          <img
            src={ReferralSpendingGameImg}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-[30px] hidden md:block"
          />
          <img
            src={ReferralMobileSpendingGameBgImg}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-[30px] md:hidden"
          />
          {/* <img src={Gift2Image} alt="" className="block sm:hidden mx-auto" /> */}
          <div className="relative py-[120px] px-6 z-[1] w-full h-full flex flex-col items-center justify-center">
            {/* <h1 className="text-[40px] leading-[50px] text-white block sm:hidden max-w-[240px] mx-auto">
            Ready to flip the script on your spending game?
          </h1> */}
            <h1 className="text-[40px] lg:text-[50px] xl:text-[60px] text-white text-center max-w-[968px] mb-5">
              404 - Page Not Found!
            </h1>
            <p className="text-base text-center md:text-lg lg:text-xl text-white mb-6 md:mb-10">
              The page you are looking for might have been removed or temporally
              unavailable.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
